import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const Cookie = () => {
  return (
    <>
    <Navbar/>
<div className="flex items-center justify-center h-full bg-violet-100 pt-28">
  <div className="text-center p-8 sm:p-16 md:p-24 lg:p-32 w-full sm:w-[90%] md:w-[75%] lg:w-[65%]">
    <h1 className="text-3xl text-indigo-500 sm:text-4xl md:text-5xl font-bold mb-4">Cookie Policy</h1>
    <p className="text-base sm:text-lg">
      Still have questions? We have answers for your non-legal inquiries on our{" "}
      <a href="/faqs" className="text-blue-600 ">FAQs page</a>. Or, learn more{" "}
      <a href="/about" className="text-blue-600 "> about us</a>.
    </p>
  </div>
</div>

    <div className="bg-gray-100 p-8">
      <div className="w-[90%] mx-auto bg-gray-50 p-8 shadow-lg rounded-lg">
        <h1 className="text-3xl font-bold text-indigo-400 mb-6">Peerless Resume Cookie Policy</h1>
        <p className="text-sm mb-2">Last Updated: May 15, 2024</p>
        <p className="text-lg mb-4">
        Welcome to Peerless Resume's Cookie Policy. This document explains how Peerless Resume ("we," "us," or "our") uses cookies and similar technologies on our website ("Website"). By accessing or using our Website, you agree to the use of cookies as described in this policy.
        </p>
        <h2 className="text-2xl font-semibold mb-4">What Are Cookies?</h2>
        <p className="mb-4">
        Cookies are small text files that are stored on your device (such as your computer or mobile device) when you visit a website. They are widely used to make websites work more efficiently and to provide information to website owners.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
        <p className="mb-4">We use cookies for various purposes, including:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Essential Cookies:  These cookies are necessary for the functioning of our Website. They enable core functionality such as page navigation and access to secure areas of the Website. You cannot opt out of these cookies.</li>
          <li>Analytical/Performance Cookies:  These cookies allow us to analyze how visitors use our Website and to track Website performance. This helps us improve the user experience and optimize our Website. These cookies collect information anonymously and do not identify individual users.</li>
          <li>Functionality Cookies:  These cookies enable the Website to remember choices you make and provide enhanced features and personalization. They may be set by us or third-party providers whose services we have added to our pages.</li>
          <li>Advertising/Targeting Cookies:  These cookies are used to deliver advertisements that are relevant to your interests. They may be used by advertising networks to display personalized ads on other websites based on your browsing behavior on our Website.</li>
        </ul>
        <h2 className="text-2xl font-semibold mb-4">Types of Cookies We Use</h2>
        <ul className="list-disc list-inside mb-4">
          <li>Session Cookies: These cookies are temporary and are deleted from your device when you close your browser session.</li>
          <li>Persistent Cookies: These cookies remain on your device for a specified period or until you delete them manually.</li>
        </ul>
        <h2 className="text-2xl font-semibold mb-4">Your Cookie Choices</h2>
        <p className="mb-4">
        You can control and manage cookies in various ways, including by adjusting your browser settings. Please note that blocking or disabling certain types of cookies may affect your experience on our Website.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Third-Party Cookies</h2>
        <p className="mb-4">
        We may use third-party services that also use cookies on our Website. These third parties may include analytics providers, advertising networks, and social media platforms. We do not control these cookies and encourage you to review the privacy policies of these third parties for more information about their cookie practices.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Changes to This Cookie Policy</h2>
        <p className="mb-4">
        We may update this Cookie Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Cookie Policy on our Website or through other means.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-4">
        If you have any questions or concerns about this Cookie Policy or our use of cookies, please contact us at [contact@peerlessresume.com].
        </p>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Cookie;

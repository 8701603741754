import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';
import { CgProfile } from 'react-icons/cg';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const navigateTo = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scroll to the top of the new page
    setMobileMenuOpen(false); // Close mobile menu after navigation
  };

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  return (
    <nav className="bg-gray-800 fixed top-0 left-0 w-full z-50 p-4">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        {/* Left side: Logo */}
        <button onClick={() => navigateTo('/')} className="text-white text-2xl font-bold">
          <img
            src="./images/Peerless grey 300 svg.svg"
            alt="Service Illustration"
            className="rounded-lg mx-auto"
          />
        </button>

        {/* Middle: Navigation Links */}
        <div className="hidden md:flex space-x-8 text-white">
          <button onClick={() => navigateTo('/services')} className="hover:text-gray-300">
            Resume Services
          </button>
          <button onClick={() => navigateTo('/testimonials')} className="hover:text-gray-300">
            Testimonials
          </button>

          {/* About with Dropdown Arrow */}
          <div className="relative">
            <button
              className="flex items-center hover:text-gray-300 focus:outline-none"
              onClick={toggleDropdown}
            >
              About <FaChevronDown className="ml-1" />
            </button>

            {/* Dropdown */}
            {isDropdownOpen && (
              <div className="absolute left-0 bg-white text-black py-2 px-4 rounded-lg shadow-lg mt-2 w-48 z-20">
                <button
                  onClick={() => navigateTo('/company')}
                  className="block text-left w-full px-4 py-2 hover:bg-gray-100"
                >
                  Company
                </button>
                <button
                  onClick={() => navigateTo('/contact')}
                  className="block text-left w-full px-4 py-2 hover:bg-gray-100"
                >
                  Contact Us
                </button>
                <button
                  onClick={() => navigateTo('/writers')}
                  className="block text-left w-full px-4 py-2 hover:bg-gray-100"
                >
                  Our Writers
                </button>
                <button
                  onClick={() => navigateTo('/faqs')}
                  className="block text-left w-full px-4 py-2 hover:bg-gray-100"
                >
                  FAQs
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Right side: Client Dashboard Logo and Buy Now Button */}
        <div className="hidden md:flex items-center space-x-4">
          <CgProfile className="text-white text-2xl" />
          <button onClick={() => navigateTo('/services')} className="block px-2 py-1">
            <button className="px-8 py-3 bg-violet-200 font-semibold rounded-full shadow-md hover:bg-violet-300 transition duration-300 ease-in-out">
              Buy Now
            </button>
          </button>
        </div>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden text-white text-2xl focus:outline-none"
          onClick={toggleMobileMenu}
        >
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden mt-4 space-y-2">
          <button onClick={() => navigateTo('/services')} className="block text-white px-2 py-1 hover:bg-gray-700">
            Resume Services
          </button>
          <button onClick={() => navigateTo('/testimonials')} className="block text-white px-2 py-1 hover:bg-gray-700">
            Testimonials
          </button>
          <div className="block text-white px-2 py-1 hover:bg-gray-700">
            <button className="hover:text-gray-300 focus:outline-none" onClick={toggleDropdown}>
              About <FaChevronDown />
            </button>
            {isDropdownOpen && (
              <div className="pl-4 space-y-2">
                <button onClick={() => navigateTo('/company')} className="block text-white hover:bg-gray-700">
                  Company
                </button>
                <button onClick={() => navigateTo('/contact')} className="block text-white hover:bg-gray-700">
                  Contact Us
                </button>
                <button onClick={() => navigateTo('/writers')} className="block text-white hover:bg-gray-700">
                  Our Writers
                </button>
                <button onClick={() => navigateTo('/faqs')} className="block text-white hover:bg-gray-700">
                  FAQs
                </button>
              </div>
            )}
          </div>
          <div className="flex items-center space-x-4 mt-4 px-2">
            <CgProfile className="text-white text-2xl" />
            <button onClick={() => navigateTo('/services')} className="block px-2 py-1">
              <button className="px-8 py-3 bg-violet-200 font-semibold rounded-full shadow-md hover:bg-violet-300 transition duration-300 ease-in-out">
                Buy Now
              </button>
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;




// src/adminpanel/components/AdminSidebar.jsx
import React from 'react';
import { NavLink } from 'react-router-dom';

const AdminSidebar = () => {
  return (
    <div className="w-1/4 bg-gray-100 p-6 border-r min-h-screen">
      <div className="flex items-center mb-10">
        <div className="flex-shrink-0">
          <div className="flex items-center justify-center bg-gray-200 rounded-full w-12 h-12">
            <span className="text-lg font-bold">AD</span>
          </div>
        </div>
        <div className="ml-3">
          <h2 className="font-bold text-lg">Admin Name</h2>
          <p className="text-sm text-gray-600">adminemail@gmail.com</p>
        </div>
      </div>
      <ul className="space-y-6">
        <li>
          <NavLink 
            to="/admin/chat" 
            className={({ isActive }) => 
              isActive ? "text-purple-600 font-bold" : "text-gray-700 font-bold hover:text-purple-600"
            }
          >
            Chat
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/admin/contact-us" 
            className={({ isActive }) => 
              isActive ? "text-purple-600 font-bold" : "text-gray-700 font-bold hover:text-purple-600"
            }
          >
            Contact Us
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/admin/orders" 
            className={({ isActive }) => 
              isActive ? "text-purple-600 font-bold" : "text-gray-700 font-bold hover:text-purple-600"
            }
          >
            Orders
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/admin/newsletter" 
            className={({ isActive }) => 
              isActive ? "text-purple-600 font-bold" : "text-gray-700 font-bold hover:text-purple-600"
            }
          >
            Newsletter
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/admin/questionnaire" 
            className={({ isActive }) => 
              isActive ? "text-purple-600 font-bold" : "text-gray-700 font-bold hover:text-purple-600"
            }
          >
            Questionnaire
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/admin/resume-evaluation" 
            className={({ isActive }) => 
              isActive ? "text-purple-600 font-bold" : "text-gray-700 font-bold hover:text-purple-600"
            }
          >
            Resume Evaluation
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default AdminSidebar;

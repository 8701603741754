// src/adminpanel/AdminDashboard.jsx
import React from 'react';
// import { Outlet } from 'react-router-dom';
import Header from '../userpanel/components/Header'; // Reusing the Header component
import AdminSidebar from './components/Sidebar';

const AdminDashboard = () => {
  return (
    <div className="h-screen">
      {/* Header */}
      <Header />

      {/* Content Section */}
      <div className="flex h-full">
        {/* Sidebar */}
        <AdminSidebar />

        {/* Main Content */}
        <div className="w-3/4 bg-white p-10 overflow-auto">
          {/* <Outlet /> */}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;

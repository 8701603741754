import React from 'react';
import { Link } from 'react-router-dom';
import { DocumentIcon } from '@heroicons/react/24/outline';
import Header from './components/Header';
import Sidebar from './components/Sidebar';

const UserDashboard = () => {
  return (
    <div className="h-screen">
      {/* Header */}
<Header/>

      {/* Content Section */}
      <div className="flex h-full "> {/* Added pt-20 to avoid header overlap */}
        {/* Sidebar */}
<Sidebar/>

        {/* Main Content */}
        <div className="w-3/4 bg-white p-10">
          <h2 className="text-3xl font-bold text-purple-600 text-center mb-8">Messages</h2>

          {/* Message Bubbles */}
          <div className="flex justify-around mb-10">
            <div className="bg-gray-200 rounded-full px-4 py-2 text-sm text-gray-600">John's Message here...</div>
            <div className="bg-purple-200 rounded-full px-4 py-2 text-sm text-purple-600">Abdul's Message here...</div>
          </div>

          {/* Document Icon Section */}
          <div className="flex flex-col items-center justify-center mt-20">
            <Link to="/myorder">
              <DocumentIcon className="w-16 h-16 text-gray-600 hover:text-purple-600 cursor-pointer mb-4" />
            </Link>
            <p className="text-2xl font-bold text-gray-600">Abdul’s is working on your documents.</p>
            <p className="text-sm text-gray-500 text-center mt-2">
              If you need to share any information with Abdul, send him a message here.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;

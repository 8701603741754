import React, { useState, useEffect } from "react";
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";

const reviews = [
  {
    name: "Charlotte Williams",
    title: "Business Development Manager",
    review: "Working with Peerless Resume Company was a game-changer for my job search. They crafted a resume that perfectly highlighted my skills and achievements. Within weeks, I secured several interviews and landed a great position. I highly recommend their professional and attentive service to anyone looking to stand out in the job market!"
  },
  {
    name: "Emily Johnson",
    title: "Marketing Manager",
    review: "I was struggling to land interviews for months, but after working with Peerless Resume, my resume was transformed into a compelling narrative of my career achievements. Their attention to detail and understanding of marketing industry trends were impressive."
  },
  {
    name: "David Smith",
    title: "Software Engineer",
    review: "Peerless Resume did a great job revamping my CV. The team was professional and understood the technical jargon necessary for my field."
  },
  {
    name: "Michael Chen",
    title: "Operations Director",
    review: "Peerless Resume worked wonders on my resume. Their deep dive into my professional background and industry allowed them to craft a document that truly showcases my leadership skills and operational achievements. I've already started receiving interest from top companies and am excited about the new opportunities ahead. The process was smooth, and the results speak for themselves!"
  },
  {
    name: "Olivia Martinez",
    title: "Healthcare Administrator",
    review: "I am thrilled with the resume Peerless Resume created for me. Their attention to detail and personalized service were top-notch. I have been contacted by several hospitals and clinics already."
  }
];

const ReviewSection = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const nextReview = () => {
    setCurrentReviewIndex((prevIndex) =>
      prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevReview = () => {
    setCurrentReviewIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextReview();
    }, 5000); // 5000ms = 5 seconds

    return () => clearInterval(interval);
  }, []);

  const { name, title, review } = reviews[currentReviewIndex];

  return (
    <div className="bg-gray-100 p-8  ">
      <div className="w-[80%] md:w-[70%] mx-auto relative">
        {/* Arrows only show on large screens */}
        <button
          onClick={prevReview}
          className="hidden lg:block absolute left-[-70px] top-[50%] transform -translate-y-[50%] text-gray-400 hover:text-gray-600 bg-white p-2 rounded-full shadow-md"
        >
          <FaArrowLeft size={30} />
        </button>
        <button
          onClick={nextReview}
          className="hidden lg:block absolute right-[-60px] top-[50%] transform -translate-y-[50%] text-gray-400 hover:text-gray-600 bg-white p-2 rounded-full shadow-md"
        >
          <FaArrowRight size={30} />
        </button>
        <h1 className="text-3xl text-indigo-400 font-semibold text-center mb-10">
          Feedback from Our Recent Clients
        </h1>
        <div className="text-center p-8 lg:text-left lg:pl-12">
          <h2 className="text-2xl font-semibold text-gray-400 mb-2">{name}</h2>
          <h3 className="text-lg text-gray-400 mb-4">{title}</h3>
          <div className="flex justify-center lg:justify-start mb-4">
            {[...Array(5)].map((_, index) => (
              <FaStar key={index} className="text-yellow-500" />
            ))}
          </div>
          <p className="text-lg w-full mx-auto lg:pr-20">"{review}"</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewSection;

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Newsletter = () => {
  const [subscribers, setSubscribers] = useState([]);

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const response = await axios.get('/api/newsletter'); // Replace with your API endpoint
        setSubscribers(response.data);
      } catch (error) {
        console.error("Error fetching newsletter subscribers:", error);
      }
    };

    fetchSubscribers();
  }, []);

  return (
    <div className="p-10">
      <h2 className="text-3xl font-bold text-purple-600 text-center mb-8">
        Newsletter Subscribers
      </h2>
      <ul>
        {subscribers.map(subscriber => (
          <li key={subscriber.id}>{subscriber.email}</li>
        ))}
      </ul>
    </div>
  );
};

export default Newsletter;

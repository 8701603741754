import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

const MyOrder = () => {
  return (
    <div className="h-screen">
      {/* Header */}
      <Header />

      {/* Content Section */}
      <div className="flex min-h-screen">
        {/* Sidebar */}
        <Sidebar />

        {/* Main Content */}
        <div className="w-3/4 bg-white p-10">
          <h2 className="text-3xl font-bold text-purple-600 text-center mb-8">My Order</h2>

          <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
            {/* Order Details */}
            <div className="mb-8 text-center">
              <p className="text-lg">
                Your order includes the <span className="font-bold text-gray-800">Premium Package</span>, 
                which features a Resume, CV, and LinkedIn profile.
              </p>
            </div>

            {/* Package Features Section */}
            <div className="space-y-4">
              <div className="p-4 bg-white border rounded-lg">
                <h3 className="font-semibold text-gray-700">Resume Writing</h3>
                <p className="text-sm text-gray-600">
                  Professional resume writing tailored to highlight your skills and accomplishments.
                </p>
              </div>

              <div className="p-4 bg-white border rounded-lg">
                <h3 className="font-semibold text-gray-700">CV Creation</h3>
                <p className="text-sm text-gray-600">
                  A detailed CV for academic or professional purposes, focusing on your experience.
                </p>
              </div>

              <div className="p-4 bg-white border rounded-lg">
                <h3 className="font-semibold text-gray-700">LinkedIn Profile</h3>
                <p className="text-sm text-gray-600">
                  A complete LinkedIn profile optimization to make you stand out to recruiters.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrder;

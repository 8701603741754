// src/pages/Writers.js
import React from 'react';
import WriterCard from '../../components/writerdata/WriterCard';
import { writerData } from '../../components/writerdata/WriterData';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';

const Writers = () => {
  return (
   <>
   <Navbar/>
    <div className="py-8 px-6 md:px-12 lg:px-24 bg-violet-50 pt-28">
    <div className="max-w-6xl mx-auto text-center ">
    <h1 className="text-4xl text-indigo-400 font-semibold mb-4">Peerless Professionals Crafting Your Future</h1>
    <h2 className="text-2xl text-black font-semibold mb-2">Our Writers Are Our Pride</h2>
    <p className="mx-auto text-lg max-w-3xl">
    At Peerless Resume, our writers are the cornerstone of our success. They invest their expertise, creativity, and dedication into every resume they craft. Each writer brings a unique set of skills and insights to the table, ensuring that your resume stands out. Get to know a few of these exceptional professionals who are committed to helping you achieve your career goals.
    </p>
  </div>
      <div className="space-y-8 py-8 pt-28 ">
        {writerData.map((writer, index) => (
          <WriterCard key={index} writer={writer} isEven={index % 2 !== 0} />
        ))}
      </div>

      <div className="max-w-6xl mx-auto text-center ">
        <p className="mx-auto font-semibold text-lg max-w-3xl">
        These are just a few gems from our team who bring their exceptional skills to every project. Our talented professionals are dedicated to transforming your career aspirations into reality. Contact us today to start working with our peerless experts.
        </p>
      </div>
    </div>
    <Footer/>
    </>
    
    
  );
};

export default Writers;

import React from 'react';

const logos = [
  '/images/Amazon.svg',
  '/images/Google.svg',
  '/images/AT&T.svg',
  '/images/Walmart.svg',
  '/images/Microsoft.svg',
];

const Logos = () => {
  return (
    <section className="bg-violet-50 p-8">
      <h1 className="text-center text-2xl  font-semibold text-black">
        Our Previous Clients Thrive at These Companies and Beyond
      </h1>
      <div className="py-8 pl-12 flex justify-center">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 w-4/5">
          {logos.map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt={`Company Logo ${index + 1}`}
              className="w-auto "
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Logos;

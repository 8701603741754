// src/data/pricingData.js
export const pricingData = [
  {
    label: "Basic",
    title: "Professional resume writing",
    subtitle: "Includes a professionally crafted resume specifically tailored to effectively highlight your skills, experiences, and career goals.",
    price: "$149",
    buttonText: "Order Now",
    features: [
      { name: "Professionally crafted document tailored to your career goals", included: true },
      { name: "Optimized for ATS and formatted to capture hiring managers’ attention.", included: true },
      { name: "Highlights skills and achievements relevant to your target job.", included: true },
      { name: "Tailored content for specific job applications and industries.", included: true },
      { name: "Available in PDF and Word formats", included: true },
      { name: "Up to 2 revisions within 7 days", included: true },
      { name: "Cover Letter", included: false },
      { name: "LinkedIn Makeover", included: false }
    ],
    footerText: "Peerless Resume: Satisfaction Guaranteed"
  },
  {
    label: "Standard",
    title: "Resume and cover letter",
    subtitle: "Features a custom resume along with a personalized cover letter to enhance your job application and stand out to potential employers.",
    price: "$199",
    buttonText: "Order Now",
    features: [
      { name: "Professionally crafted document tailored to your career goals", included: true },
      { name: "Optimized for ATS and formatted to capture hiring managers’ attention.", included: true },
      { name: "Highlights skills and achievements relevant to your target job.", included: true },
      { name: "Tailored content for specific job applications and industries.", included: true },
      { name: "Available in PDF and Word formats", included: true },
      { name: "Up to 2 revisions within 7 days", included: true },
      { name: "Cover Letter", included: true },
      { name: "LinkedIn Makeover", included: false }
    ],
    footerText: "Peerless Resume: Satisfaction Guaranteed."
  },
  {
    label: "Premium",
    title: "Resume, cover letter, and LinkedIn",
    subtitle: "Offers a comprehensive service including a professionally written resume, a tailored cover letter, and an optimized LinkedIn profile.",
    price: "$299",
    buttonText: "Order Now",
    features: [
      { name: "Professionally crafted document tailored to your career goals", included: true },
      { name: "Optimized for ATS and formatted to capture hiring managers’ attention.", included: true },
      { name: "Highlights skills and achievements relevant to your target job.", included: true },
      { name: "Tailored content for specific job applications and industries.", included: true },
      { name: "Available in PDF and Word formats", included: true },
      { name: "Up to 2 revisions within 7 days", included: true },
      { name: "Cover Letter", included: true },
      { name: "LinkedIn Makeover", included: true }
    ],
    footerText: "Peerless Resume: Satisfaction Guaranteed."
  }
];

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ContactUs = () => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get('/api/contacts'); // Replace with your API endpoint
        setContacts(response.data);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, []);

  return (
    <div className="p-10">
      <h2 className="text-3xl font-bold text-purple-600 text-center mb-8">
        Contact Us
      </h2>
      <ul>
        {contacts.map(contact => (
          <li key={contact.id}>{contact.name}: {contact.message}</li>
        ))}
      </ul>
    </div>
  );
};

export default ContactUs;

import React, { useState } from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";

const Faqs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "What services does Peerless Resume offer?",
      answer:
        "Peerless Resume provides professional resume writing, cover letter creation, and LinkedIn profile optimization. Our team of experts helps job seekers craft compelling documents that enhance their chances of landing interviews and securing job offers.",
    },
    {
      question: "How does the resume writing process work?",
      answer:
        "Once you place an order, you will be asked to complete a brief questionnaire about your career history, skills, and goals. Our resume writers will then use this information to create a customized resume. You will have the opportunity to review and request revisions before finalizing your documents.",
    },
    {
      question: "What is the turnaround time for resume writing services?",
      answer:
        "Standard turnaround time is typically 5-7 business days. However, we offer expedited services if you need your resume sooner. Contact us directly to discuss urgent requests and associated fees.",
    },
    {
      question: "How much does it cost to use Peerless Resume's services?",
      answer:
        "YOur pricing varies based on the complexity of the resume and the specific services requested. For a detailed quote, please visit our Pricing page or contact our support team.",
    },
    {
      question: "Can I request revisions to my resume?",
      answer:
        "Yes, we offer a revision process to ensure you’re satisfied with the final product. You can request revisions within a specified period after receiving your initial draft, typically up to 7 days.",
    },
    {
      question: "Do you provide services for all career levels?",
      answer:
        "Absolutely! We cater to all career levels, from entry-level positions to executive roles. Our team has experience working with professionals across various industries and career stages.",
    },
    {
      question: "How do I know if my resume will be effective?",
      answer:
        "Our resumes are crafted based on industry best practices and tailored to highlight your unique skills and achievements. We also offer a satisfaction guarantee, and our team is available to provide additional guidance and support throughout the job search process.",
    },
    {
      question: "Can you help with LinkedIn profile optimization?",
      answer:
        "Yes, we offer LinkedIn profile optimization services. Our experts will enhance your LinkedIn profile to improve visibility, showcase your expertise, and attract potential employers or networking opportunities.",
    },
    {
      question:
        "What information do I need to provide for the resume writing process?",
      answer:
        "You'll need to provide details about your work experience, education, skills, achievements, and career goals. Our comprehensive questionnaire will guide you through the necessary information to create a compelling resume.",
    },
    {
      question: "What if I’m not satisfied with the final resume?",
      answer:
        "If you’re not satisfied with the final resume, we offer a revision process to address any concerns or adjustments needed. We are committed to ensuring that you’re pleased with the final product and will work with you to make necessary changes.",
    },
  ];

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
    <Navbar/>
    <div className=" mx-auto px-12 bg-violet-50  py-8 pt-28">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-indigo-400 mb-4">
          Frequently Asked Questions
        </h1>
        <p className="text-lg text-gray-700">
          A Complete Guide to Peerless Resume: Expert Advice, Process Insights,
          and Common Concerns
        </p>
      </div>

      <div className="space-y-8">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b border-gray-200 pb-4">
            <h2
              className="text-xl font-semibold cursor-pointer text-black"
              onClick={() => toggleFaq(index)}
            >
              {faq.question}
            </h2>
            {openIndex === index && (
              <p className="mt-4 w-[65%] text-gray-700">{faq.answer}</p>
            )}
          </div>
        ))}
      </div>
      
        <div className=" py-8 max-w-6xl mx-auto text-center">
          <p className="mx-auto font-semibold text-lg max-w-3xl">

            Have additional questions or need more information? We’re here to
            help! Feel free to reach out to us through{" "}
      <a href="/faqs" className="text-blue-600 ">email</a>. Or {" "}
      <a href="/about" className="text-blue-600 "> live chat</a> for
      any inquiries or assistance you might need.{""}
          </p>
        </div>
      
    </div>
    <Footer/>
    </>
  );
};

export default Faqs;

import React from 'react';
import { Link } from 'react-router-dom';

const Header= () => {
  return (
    <header className="w-full bg-gray-100 shadow  top-0 left-0 ">
    <div className="max-w-full mx-auto px-6 py-4 flex justify-between items-center">
      {/* Logo */}
      <div className="flex items-center">
      <Link to="/user" className="text-white text-2xl font-bold">
      <img
        src="./images/Peerless Logo.svg"
        alt="Service Illustration"
        className="rounded-lg mx-auto"
      />
      </Link>
      </div>

      {/* Greeting */}
      <h2 className="text-2xl font-bold text-purple-600">Hi, Abdul!</h2>

      {/* Welcome Message */}
      <div className="rounded-full bg-gray-200 px-6 py-2">
        <h2 className="text-md font-medium text-gray-700">Welcome to Peerless Resume</h2>
      </div>
    </div>
  </header>
  );
};

export default Header;

// Questionnaire.js
import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

const Questionnaire = () => {
  return (
    // <div className="flex h-screen">
    //   {/* Sidebar */}
    //   <Sidebar />

    //   {/* Main Content Container */}
    //   <div className="flex flex-col flex-1">
    //     {/* Header */}
    //     <Header />

    //     {/* Content Section */}
    //     <div className="flex-1 p-10 mt-16 ml-64"> {/* mt-16 to avoid overlap with header */}
    //       <h2 className="text-3xl font-bold text-purple-600 text-center mb-8">
    //         Questionnaire
    //       </h2>

    //       <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
    //         {/* File Upload Section */}
    //         <div className="mb-8 text-center">
    //           <p className="text-gray-700 font-semibold mb-2">
    //             Submit your most recent resume by dragging it here or uploading it directly.
    //           </p>
    //           <div className="w-full bg-white border-dashed border-2 border-gray-300 p-6 rounded-lg hover:shadow-md">
    //             <svg
    //               className="mx-auto h-12 w-12 text-gray-400"
    //               xmlns="http://www.w3.org/2000/svg"
    //               fill="none"
    //               viewBox="0 0 24 24"
    //               stroke="currentColor"
    //               aria-hidden="true"
    //             >
    //               <path
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //                 strokeWidth="2"
    //                 d="M4 16v1a2 2 0 002 2h12a2 2 0 002-2v-1M16 8l-4-4m0 0L8 8m4-4v12"
    //               />
    //             </svg>
    //           </div>
    //         </div>

    //         {/* Links Section */}
    //         <div className="mb-8">
    //           <p className="font-semibold text-gray-700 mb-4">
    //             Please provide links to at least two job openings you are interested in applying for.
    //           </p>
    //           <div className="space-y-4">
    //             <input
    //               type="text"
    //               placeholder="Link1: ___________________________________________"
    //               className="w-full px-4 py-2 border rounded-lg"
    //             />
    //             <input
    //               type="text"
    //               placeholder="Link2: ___________________________________________"
    //               className="w-full px-4 py-2 border rounded-lg"
    //             />
    //           </div>
    //         </div>

    //         {/* Questionnaire Section */}
    //         <div className="space-y-6">
    //           <div>
    //             <p className="font-semibold text-gray-700 mb-2">
    //               What are your biggest concerns with your current resume?
    //             </p>
    //             <textarea
    //               className="w-full h-20 px-4 py-2 border rounded-lg"
    //               placeholder="Answer: __________________________________________________________"
    //             />
    //           </div>

    //           <div>
    //             <p className="font-semibold text-gray-700 mb-2">
    //               What do you consider the strongest aspect of your current resume?
    //             </p>
    //             <textarea
    //               className="w-full h-20 px-4 py-2 border rounded-lg"
    //               placeholder="Answer: __________________________________________________________"
    //             />
    //           </div>

    //           <div>
    //             <p className="font-semibold text-gray-700 mb-2">
    //               Are there any obstacles you believe are hindering your ability to secure a new position?
    //             </p>
    //             <textarea
    //               className="w-full h-20 px-4 py-2 border rounded-lg"
    //               placeholder="Answer: __________________________________________________________"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>


    <div className=" h-screen">
      {/* Header */}
<Header/>

      {/* Content Section */}
      <div className="flex min-h-screen "> {/* Added pt-20 to avoid header overlap */}
        {/* Sidebar */}
<Sidebar/>

        {/* Main Content */}
        <div className="w-3/4 bg-white p-10">
          <h2 className="text-3xl font-bold text-purple-600 text-center mb-8">Questionnaire</h2>

          <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
             {/* File Upload Section */}
            <div className="mb-8 text-center">
               <p className="text-gray-700 font-semibold mb-2">
                 Submit your most recent resume by dragging it here or uploading it directly.
               </p>
               <div className="w-full bg-white border-dashed border-2 border-gray-300 p-6 rounded-lg hover:shadow-md">
                 <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 16v1a2 2 0 002 2h12a2 2 0 002-2v-1M16 8l-4-4m0 0L8 8m4-4v12"
                  />
                </svg>
              </div>
            </div>

            {/* Links Section */}
            <div className="mb-8">
              <p className="font-semibold text-gray-700 mb-4">
                Please provide links to at least two job openings you are interested in applying for.
              </p>
              <div className="space-y-4">
                <input
                  type="text"
                  placeholder="Link1: ___________________________________________"
                  className="w-full px-4 py-2 border rounded-lg"
                />
                <input
                  type="text"
                  placeholder="Link2: ___________________________________________"
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>
            </div>

            {/* Questionnaire Section */}
            <div className="space-y-4">
              <div>
                <p className="font-semibold text-gray-700 mb-2">
                  What are your biggest concerns with your current resume?
                </p>
                <textarea
                  className="w-full  px-4 py-2 border rounded-lg"
                  placeholder="Answer: __________________________________________________________"
                />
              </div>

              <div>
                <p className="font-semibold text-gray-700 mb-2">
                  What do you consider the strongest aspect of your current resume?
                </p>
                <textarea
                  className="w-full px-4 py-2 border rounded-lg"
                  placeholder="Answer: __________________________________________________________"
                />
              </div>

              <div>
                <p className="font-semibold text-gray-700 mb-2">
                  Are there any obstacles you believe are hindering your ability to secure a new position?
                </p>
                <textarea
                  className="w-full  px-4 py-2 border rounded-lg"
                  placeholder="Answer: __________________________________________________________"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Questionnaire;

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AdminQuestionnaire = () => {
  const [questionnaires, setQuestionnaires] = useState([]);

  useEffect(() => {
    const fetchQuestionnaires = async () => {
      try {
        const response = await axios.get('/api/questionnaires'); // Replace with your API endpoint
        setQuestionnaires(response.data);
      } catch (error) {
        console.error("Error fetching questionnaires:", error);
      }
    };

    fetchQuestionnaires();
  }, []);

  return (
    <div className="p-10">
      <h2 className="text-3xl font-bold text-purple-600 text-center mb-8">
        Questionnaire Data
      </h2>
      <ul>
        {questionnaires.map(q => (
          <li key={q.id}>{q.question}: {q.answer}</li>
        ))}
      </ul>
    </div>
  );
};

export default AdminQuestionnaire;

import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const testimonials = [
  {
    paragraph:
      "Peerless Resume provided an exceptional resume that has already resulted in multiple interview opportunities. Their expertise and personalized approach were invaluable.",
    stars: 5,
    name: "Sophia Lee, Research Scientist",
  },
  {
    paragraph:
      "I’m extremely satisfied with the resume service from Peerless Resume. The quality of their work and the impact it has had on my career have been exceptional.",
    stars: 5,
    name: "Ava Thompson, Project Coordinator",
  },
  {
    paragraph:
      "The resume I received from them was perfectly tailored to my career goals and industry. It has made a substantial difference in my job search results.",
    stars: 5,
    name: "Ethan Morris, Marketing Manager",
  },
  {
    paragraph:
      "The team at Peerless Resume exceeded my expectations with their resume writing services. My new resume has opened doors to new career opportunities, and I am very pleased with their work.",
    stars: 5,
    name: "Ryan Scott, Operations Specialist",
  },
  {
    paragraph:
      "Peerless Resume provided an exceptional service. My resume is now polished and professionally written, and it has already led to exciting new opportunities.",
    stars: 5,
    name: "Lily Evans, Executive Director",
  },
  {
    paragraph:
      "The quality of my new resume from Peerless Resume is outstanding. It has significantly improved my job prospects and helped me secure interviews with top companies.",
    stars: 5,
    name: "William Taylor, IT Consultant",
  },
  {
    paragraph:
      "Thanks to Peerless Resume, I now have a resume that accurately represents my skills and experience. Their personalized approach and professional service have been instrumental in my job search.",
    stars: 5,
    name: "Samantha Clark, Administrative Assistant",
  },
  {
    paragraph:
      "I was impressed with the quality and professionalism of Peerless Resume. Their service was worth every penny.",
    stars: 5,
    name: "Roy Anderson, Financial Planner",
  },
  {
    paragraph:
      "Peerless Resume worked wonders for my career. Their team crafted a resume and LinkedIn profile that truly showcased my skills and achievements. Within a month of using their services, I secured my dream job as a Senior Marketing Manager.",
    stars: 5,
    name: "Emily Johnson, Senior Marketing Manager",
  },
  {
    paragraph:
      "The level of service I received from Peerless Resume was exceptional. They created a standout resume and cover letter that not only captured my professional experience but also helped me land multiple job offers as a Financial Analyst in record time. This investment was definitely worth it.",
    stars: 5,
    name: "Michael Lee, Financial Analyst",
  },
  {
    paragraph:
      "I was thoroughly impressed with Peerless Resume. Their team provided a meticulous and professional touch to my resume. Thanks to their outstanding work, I’ve had numerous job opportunities as a Software Engineer. I’m genuinely pleased with the results.",
    stars: 5,
    name: "Sarah Martinez, Software Engineer",
  },
  {
    paragraph:
      "Peerless Resume exceeded all of my expectations. The resume and LinkedIn profile they designed for me were incredibly polished and professional. As a result, I received several job offers within a short period for a Project Manager position. Their expertise truly made a difference in my job search.",
    stars: 5,
    name: "John Davis, Project Manager",
  },
  {
    paragraph:
      "Working with Peerless Resume was a fantastic experience. Their tailored approach to my resume and cover letter made a significant impact on my job search. I am now happily employed in a Senior Product Manager role. Already recommended their services to several friends.",
    stars: 5,
    name: "Lisa Kelly, Senior Product Manager",
  },
  {
    paragraph:
      "Peerless Resume’s attention to detail and professionalism were evident in every aspect of their service. They provided me with a resume that was not only visually appealing but also highly effective.",
    stars: 5,
    name: "Brian Harris, Operations Director",
  },
  {
    paragraph:
      "I could not be more satisfied with Peerless Resume. Their expertise in crafting a compelling resume led to a major turnaround in my job search. I received numerous interviews and offers shortly after using their services as an HR Specialist.",
    stars: 5,
    name: "Rachel Wilson, HR Specialist",
  },
  {
    paragraph:
      "The team at Peerless Resume was incredible. Their resume and LinkedIn profile services were expertly handled, resulting in a new Senior Financial Advisor position for me within just a few weeks.",
    stars: 5,
    name: " David Thompson, Senior Financial Advisor",
  },
  {
    paragraph:
      "Peerless Resume’s services were nothing short of outstanding. I quickly received offers from top companies for a Marketing Director role, thanks to their exceptional work. I highly recommend them.",
    stars: 5,
    name: "Jessica Foster, Marketing Director",
  },
  {
    paragraph:
      "I am pleased with the results from Peerless Resume. Their resume and cover letter writing services were top-notch, and they helped me secure several interviews for a Business Development Manager position.",
    stars: 5,
    name: "Alan Collins, Business Development Manager",
  },
  {
    paragraph:
      "Thanks to their efforts, I landed a fantastic new role as an IT Consultant in record time.",
    stars: 5,
    name: "Laura Green, IT Consultant",
  },
  {
    paragraph:
      "I am absolutely thrilled with the results from Peerless Resume. Their expert writing transformed my resume and cover letter, leading to an excellent job offer as a Data Analyst faster than I could have hoped for.",
    stars: 5,
    name: "Tom Smith, Data Analyst",
  },
  {
    paragraph:
      "The work done by Peerless Resume was outstanding. I quickly began receiving job offers, and I’m very pleased with the results of their service.",
    stars: 5,
    name: " Megan Brown, Supply Chain Manager",
  },
  {
    paragraph:
      "Peerless Resume was a game-changer for me. Their professional approach and expertly crafted resume helped me stand out in a competitive job market. I received multiple job offers for a Sales Executive role and am now in a great position. Highly recommend their services.",
    stars: 5,
    name: " Chris Anderson, Sales Executive",
  },
  {
    paragraph:
      "The quality of service from Peerless Resume was exceptional. They created a resume and cover letter that perfectly captured my professional experience and achievements.",
    stars: 5,
    name: "Karen Taylor, Financial Controller",
  },
  {
    paragraph:
      "I am extremely pleased with Peerless Resume’s service. Their expertly crafted resume and LinkedIn profile gave me a significant edge in my job search for a Digital Marketing Manager position. I was able to secure a high-level role quickly, thanks to their exceptional work. Highly recommended!",
    stars: 5,
    name: " Brian Walker, Digital Marketing Manager",
  },
  {
    paragraph:
      "This resume writing services provided me with a standout resume and LinkedIn profile that led to numerous job offers as an Engineering Manager. Their expertise is evident, and I am very satisfied with the results.",
    stars: 5,
    name: "Olivia Martin, Engineering Manager",
  },
  {
    paragraph:
      "I’m so grateful for Peerless Resume. Their resume and cover letter services were just what I needed to boost my job search as a Graphic Designer. I got what I needed the most.",
    stars: 5,
    name: "Jake Peterson, Graphic Designer",
  },
];

const Testimonials = () => {
    return (
      <>
      <Navbar/>
      <section className="bg-violet-50 py-8 pt-28">
        {/* Heading Section */}
        <div className="container mx-auto text-center mb-12">
          <h1 className="text-4xl font-bold text-indigo-400 mb-4">
            Client Success Showcase
          </h1>
          <p className="text-lg text-gray-700">
            See how our clients feel about the services we provide through (Regularly updated)
          </p>
        </div>
  
        {/* Testimonials Section */}
        <div className="container w-[80%] mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="p-6 bg-gray-100 rounded-lg shadow-md">
              <p className="text-gray-800 mb-4">{testimonial.paragraph}</p>
              <div className="flex justify-end mb-4">
                {[...Array(testimonial.stars)].map((_, i) => (
                  <svg
                    key={i}
                    className="w-4 h-4 text-yellow-500 fill-current mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10 15l-5.878 3.09L5.121 12.3 0 8.365l6.071-.895L10 2l3.93 5.47 6.071.895-5.12 3.935 1.24 5.79z" />
                  </svg>
                ))}
              </div>
              <p className="text-sm text-gray-600 text-right">- {testimonial.name}</p>
            </div>
          ))}
        </div>
      </section>
      <Footer/>
      </>
    );
  };
  
  export default Testimonials;
  

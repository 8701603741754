// src/data/writerData.js

export const writerData = [
    {
      name: "Emily Carter",
      title: "Lead Resume Writer",
      experience: "12+ years in resume writing and career coaching.",
      expertise: "Specializes in executive and senior management resumes with a focus on strategic impact and leadership.",
      certifications: "Certified Professional Resume Writer (CPRW), Certified Executive Resume Master (CERM).",
      image: "/images/Emily.svg" // Placeholder image, replace with actual image URL
    },
    {
        name: "Michael Thompson",
        title: "Senior Resume Writer",
        experience: "10 years in resume writing and human resources۔",
        expertise: "Focuses on mid-career professionals and career changers, emphasizing skills and achievements.",
        certifications: "CPRW, Certified Professional Career Coach (CPCC).",
        image: "/images/Michael.svg" // Placeholder image, replace with actual image URL
      },
      {
        name: "Sarah Patel",
        title: "Resume Specialist",
        experience: "8 years in resume writing and IT recruitment.",
        expertise: " Expert in crafting resumes for IT and technology professionals, with a knack for highlighting technical skills.",
        certifications: "CPRW, Certified Technical Resume Writer (CTRW).",
        image: "/images/Sarah.svg" // Placeholder image, replace with actual image URL
      },
      {
        name: "David Lewis",
        title: "Junior Resume Writer",
        experience: "5 years in resume writing and career services.",
        expertise: "Focuses on entry-level and recent graduate resumes, highlighting academic achievements and internships.",
        certifications: "CPRW, Certified Professional Resume Specialist (CPRS).",
        image: "/images/David Lewis.svg"// Placeholder image, replace with actual image URL
      },
      {
        name: "Jessica Morgan",
        title: "Career Consultant",
        experience: "9 years in resume writing and federal job consulting.",
        expertise: "Specializes in federal resumes and military-to-civilian transitions.",
        certifications: "CPRW, Federal Resume Specialist (FRS).",
        image: "/images/Jessica.svg" // Placeholder image, replace with actual image URL
      },
      {
        name: "Andrew Kim",
        title: "Resume Writer",
        experience: "7 years in resume writing and healthcare recruitment.",
        expertise: "Focuses on healthcare and medical professions, emphasizing clinical skills and professional certifications.",
        certifications: "CPRW, Certified Healthcare Resume Writer (CHRW).",
        image: "/images/Andrew Kim.svg" // Placeholder image, replace with actual image URL
      },
      {
        name: "Olivia Harris",
        title: "Senior Resume Editor",
        experience: "11 years in resume editing and professional writing.",
        expertise: "Expert in editing and proofreading resumes, ensuring clarity and impact for all career levels.",
        certifications: "CPRW, Certified Resume Writer and Editor (CRWE).",
        image: "/images/Olivia Harris.svg" // Placeholder image, replace with actual image URL
      },
      {
        name: "Daniel Roberts",
        title: "Resume Writer",
        experience: "6 years in resume writing and finance recruitment.",
        expertise: "Specializes in finance and accounting resumes, emphasizing financial achievements.",
        certifications: "CPRW, Certified Financial Resume Writer (CFRW).",
        image: "/images/Danniel Roberts.svg" // Placeholder image, replace with actual image URL
      },
      {
        name: "Natalie Green",
        title: "Resume and LinkedIn Specialist",
        experience: "8 years in resume writing and social media career branding",
        expertise: "Combines resume writing with LinkedIn profile optimization, helping clients build a cohesive online presence.",
        certifications: "CPRW, LinkedIn Profile Expert (LPX).",
        image: "/images/Natalie Green.svg" // Placeholder image, replace with actual image URL
      },
      {
        name: "James Turner",
        title: "Resume Writer",
        experience: "7 years in resume writing and creative recruitment.",
        expertise: "Focuses on creative industries and marketing resumes, showcasing portfolios and accomplishments.",
        certifications: "CPRW, Certified Creative Resume Writer (CCRW).",
        image: "/images/James Turner.svg" // Placeholder image, replace with actual image URL
      },

  ];
  
// import Navbar from './components/navbar/Navbar';
// import './index.css';

// function App() {
//   return (
//     <div className="App">
//       <Navbar/>
//     </div>
//   );
// }

// export default App;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import Privacy from "./pages/privacy/Privacy";
import Cookie from "./pages/cookie/Cookie";
import Terms from "./pages/terms/Terms";
import ResumeServices from "./pages/services/ResumeServices";
import DataPolicy from "./pages/data policy/DataPolicy";
import Footer from "./components/footer/Footer";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import Faqs from "./pages/faqs/Faqs";
import Testimonials from "./pages/testimonials/Testimonials";
import Writer from "./pages/writer/Writers";

import UserDashboard from "./userpanel/UserDashboard";
import Messages from "./userpanel/components/Messages";
import Questionnaire from "./userpanel/components/Questionnaire";
import MyOrder from "./userpanel/components/MyOrder";
import AdminDashboard from "./adminpanel/AdminDashboard";
import ResumeEvaluation from "./adminpanel/pages/ResumeEvaluation";
import Orders from "./adminpanel/pages/Orders";
import ContactUs from "./adminpanel/pages/ContactUs";
import Newsletter from "./adminpanel/pages/Newsletter";
import Chat from "./adminpanel/pages/Chat";
import AdminQuestionnaire from "./adminpanel/pages/Questionnaire";

// import Event from './pages/Event';
// import Services from './pages/Services';
// import Registration from './pages/Registration';
// import Profile from './pages/Profile';
// import Dashboard from './pages/Dashboard';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cookie" element={<Cookie />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/services" element={<ResumeServices />} />
        <Route path="/data policy" element={<DataPolicy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/writers" element={<Writer />} />
        <Route path="/company" element={<About />} />

        {/* User Panel routes */}
        <Route path="/user" element={<UserDashboard />} />
        <Route path="/messages" element={<UserDashboard />} />
        <Route path="/questionnaire" element={<Questionnaire />} />
        <Route path="/myorder" element={<MyOrder />} />

        {/* Admin Panel Routes */}
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/admin/resume-evaluation" element={<ResumeEvaluation />} />
        <Route path="/admin/orders" element={<Orders />} />
        <Route path="/admin/contact" element={<ContactUs />} />
        <Route path="/admin/newsletter" element={<Newsletter />} />
        <Route path="/admin/chat" element={<Chat />} />
        <Route path="/admin/questionnaire" element={<AdminQuestionnaire />} />
      </Routes>
    </Router>
  );
}

export default App;

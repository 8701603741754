import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const Terms = () => {
  return (
    <>
    <Navbar/>
<div className="flex items-center justify-center h-full bg-violet-100 pt-28">
  <div className="text-center p-8 sm:p-16 md:p-24 lg:p-32 w-full sm:w-[90%] md:w-[75%] lg:w-[65%]">
    <h1 className="text-3xl text-indigo-500 sm:text-4xl md:text-5xl font-bold mb-4">Terms and Conditions</h1>
    <p className="text-base sm:text-lg">
      Still have questions? We have answers for your non-legal inquiries on our{" "}
      <a href="/faqs" className="text-blue-600 ">FAQs page</a>. Or, learn more{" "}
      <a href="/about" className="text-blue-600 "> about us</a>.
    </p>
  </div>
</div>

    <div className="bg-gray-100 p-8">
      <div className="w-[90%] mx-auto bg-gray-50 p-8 shadow-lg rounded-lg">
        <h1 className="text-3xl text-indigo-400 font-bold mb-6">Peerless Resume Terms and Conditions</h1>
        <p className="text-sm mb-2">Last Updated: June 15, 2024</p>
        <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
        <p className="mb-4">
        Welcome to Peerless Resume! These Terms and Conditions ("Terms") govern your use of our website, products, and services (collectively, the "Services") provided by Peerless Resume ("we," "us," or "our"). By accessing or using our Services, you agree to be bound by these Terms.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Use of Services</h2>
        <p className="mb-4">
        You must be at least 18 years old to use our Services. By using our Services, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Service Description</h2>
        <p className="mb-4">
        Peerless Resume provides resume writing and editing services to help individuals create professional resumes and cover letters. Our Services may also include career coaching, job search assistance, and related offerings.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Payment Terms</h2>
        <p className="mb-4">
        Payment for our Services is required upfront and must be made in full before any work begins. We accept various payment methods, including credit cards, PayPal, and other secure payment options.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Refund Policy</h2>
        <p className="mb-4">
        We strive for customer satisfaction. If you are not satisfied with our Services, please contact us within 7 days of receiving your resume for a refund. Refunds are subject to our discretion and may be issued on a case-by-case basis.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Intellectual Property</h2>
        <p className="mb-4">
        All content, materials, and intellectual property provided through our Services, including resumes, cover letters, and career resources, are owned by Peerless Resume. You may use these materials for personal, non-commercial use only and may not reproduce, distribute, or modify them without our prior written consent.
        </p>
        <h2 className="text-2xl font-semibold mb-4">User Content</h2>
        <p className="mb-4">
        By submitting content (such as resumes, cover letters, or feedback) through our Services, you grant Peerless Resume a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content worldwide in any media.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Confidentiality</h2>
        <p className="mb-4">
        We respect your privacy and handle your personal information in accordance with our Privacy Policy. However, we cannot guarantee the confidentiality of any information or materials provided through our Services, and you are solely responsible for protecting the confidentiality of your account credentials and any sensitive information you share with us.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Limitation of Liability</h2>
        <p className="mb-4">
        To the fullest extent permitted by law, Peerless Resume and its affiliates, officers, directors, employees, agents, and licensors shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in any way connected with your use of our Services.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Indemnification</h2>
        <p className="mb-4">
        You agree to indemnify, defend, and hold harmless Peerless Resume and its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or related to your use of our Services or any violation of these Terms.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Modifications to Terms</h2>
        <p className="mb-4">
        We reserve the right to modify or update these Terms at any time without prior notice. Any changes to these Terms will be effective immediately upon posting. Your continued use of our Services after any such changes constitutes your acceptance of the revised Terms.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Governing Law</h2>
        <p className="mb-4">
        These Terms and any disputes arising from or relating to them shall be governed by the laws of [Jurisdiction], without regard to its conflict of law principles.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-4">
        If you have any questions or concerns about these Terms or our Services, please contact us at [contact@peerlessresume.com].
        </p>
        
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Terms;

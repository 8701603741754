import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className="w-1/4 bg-gray-100 p-6 border-r min-h-screen">
<div className="flex items-center mb-10">
  <div className="flex-shrink-0">
    <div className="flex items-center justify-center bg-gray-200 rounded-full w-12 h-12">
      <span className="text-lg font-bold">CN</span>
    </div>
  </div>
  <div className="ml-3">
    <h2 className="font-bold text-lg">Customer Name</h2>
    <p className="text-sm text-gray-600">Customeremail@gmail.com</p>
  </div>
</div>
<ul className="space-y-6">
  <li>
    <Link to="/messages" className="text-gray-700 font-bold hover:text-purple-600">Messages</Link>
  </li>
  <li>
    <Link to="/questionnaire" className="text-gray-700 font-bold hover:text-purple-600">Questionnaire</Link>
  </li>
  <li>
    <Link to="/myorder" className="text-gray-700 font-bold hover:text-purple-600">My Order</Link>
  </li>
</ul>
</div>
  );
};

export default Sidebar;


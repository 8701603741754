// src/components/WriterCard.js
import React from 'react';

const WriterCard = ({ writer, isEven }) => {
  return (
    <div className={`flex flex-col lg:flex-row rounded-2xl items-center lg:items-start bg-gray-100 rounded-lg shadow-lg p-6 mb-8 ${isEven ? 'lg:flex-row-reverse' : ''}`}>
      <div className="flex-shrink-0">
        <img src={writer.image} alt={writer.name} className="w-32 h-32 rounded-full border-4 border-indigo-400" />
      </div>
      <div className={`lg:ml-6 ${isEven ? 'lg:mr-44' : ''}`}>
        <h3 className="text-2xl font-bold text-indigo-600">{writer.name}</h3>
        <h4 className="text-gray-600 mt-2"><strong>Title:</strong> {writer.title}</h4>
        <p className="text-gray-600 mt-2"><strong>Experience:</strong> {writer.experience}</p>
        <p className="text-gray-600 mt-2"><strong>Expertise:</strong> {writer.expertise}</p>
        <p className="text-gray-600 mt-2"><strong>Certifications:</strong> {writer.certifications}</p>
      </div>
    </div>
  );
};

export default WriterCard;

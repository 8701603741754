import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const DataPolicy = () => {
  return (

    <>
    <Navbar/>
<div className="flex items-center justify-center h-full bg-violet-100 pt-28">
  <div className="text-center p-8 sm:p-16 md:p-24 lg:p-32 w-full sm:w-[90%] md:w-[75%] lg:w-[65%]">
    <h1 className="text-3xl text-indigo-500 sm:text-4xl md:text-5xl font-bold mb-4">Data Policy</h1>
    <p className="text-base sm:text-lg">
      Still have questions? We have answers for your non-legal inquiries on our{" "}
      <a href="/faqs" className="text-blue-600 ">FAQs page</a>. Or, learn more{" "}
      <a href="/about" className="text-blue-600 "> about us</a>.
    </p>
  </div>
</div>
      <div className="bg-gray-100 p-8">
        <div className="w-[90%] mx-auto bg-gray-50 p-8 shadow-lg rounded-lg">
          <h1 className="text-3xl font-bold text-indigo-400 mb-6">Do Not Sell Data Policy</h1>
          <h2 className="text-2xl font-semibold mb-4">Our Commitment to Your Privacy</h2>
          <h3 className="text-xl font-semibold mb-4"> Data Integrity and Confidentiality</h3>
          <p className="mb-4">
            Peerless Resume Company upholds a strict policy of never selling, renting, or trading your personal information to third parties. We regard your data as confidential and use it exclusively to provide and enhance our resume writing and career services. Our commitment extends to ensuring that your information remains secure and is used solely for the purposes you have authorized.
          </p>
          <h3 className="text-xl font-semibold mb-4"> Purpose of Data Collection</h3>
          <p className="mb-4">
            We collect personal information to deliver personalized services tailored to your career needs. This includes crafting your resume, offering career advice, and managing communications between you and our team. We do not use your data for any other purpose or share it beyond what is necessary to fulfill our service commitments.
          </p>
          <h3 className="text-xl font-semibold mb-4"> Robust Data Protection Measures</h3>
          <p className="mb-4">
            To safeguard your data, we implement state-of-the-art security measures, including encryption, secure servers, and access controls. Our data protection strategies are designed to prevent unauthorized access, data breaches, and other security threats. Regular audits and updates are performed to enhance our security posture and address potential vulnerabilities.
          </p>
          <h3 className="text-xl font-semibold mb-4"> Rights and Access</h3>
          <p className="mb-4">
            You have the right to access, correct, or request the deletion of your personal data. Should you wish to exercise these rights, please contact us at [your contact information]. We will handle your request in accordance with applicable data protection laws and ensure that your data is managed appropriately.
          </p>
          <h3 className="text-xl font-semibold mb-4"> Policy Transparency and Updates</h3>
          <p className="mb-4">
            We are committed to maintaining transparency about our data practices. Our privacy policy outlines the specifics of how your data is collected, used, and protected. Any modifications to this policy will be communicated to you in a timely manner to ensure you are aware of any changes to how your data is handled.
          </p>
          <h3 className="text-xl font-semibold mb-4"> Third-Party Service Providers</h3>
          <p className="mb-4">
            While we do not sell your data, we may collaborate with trusted third-party service providers who assist us in delivering our services. These providers are bound by strict confidentiality agreements and are only authorized to use your data as necessary to support our service operations. They are prohibited from using your data for any other purposes.
          </p>
          <h3 className="text-xl font-semibold mb-4"> Data Retention and Disposal</h3>
          <p className="mb-4">
            We retain your personal data only for as long as necessary to achieve the purposes for which it was collected or as required by legal obligations. Once your data is no longer needed, it will be securely deleted or anonymized to prevent any unauthorized access or use.
          </p>
          <h3 className="text-xl font-semibold mb-4"> Your Feedback and Concerns</h3>
          <p className="mb-4">
            We welcome your feedback and are here to address any concerns you may have about our data practices. If you have questions or require further information, please reach out to us at [your contact information]. Our dedicated team is committed to resolving any issues and ensuring that your privacy is upheld.
          </p>
          <h2 className="text-2xl font-semibold mb-4">Our Promise</h2>
          <p className="mb-4">
            Peerless Resume Company is dedicated to maintaining the highest standards of data protection and privacy. We value your trust and are committed to handling your personal information with the utmost care and respect.
          </p>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default DataPolicy;

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ResumeEvaluation = () => {
  const [evaluations, setEvaluations] = useState([]);

  useEffect(() => {
    const fetchEvaluations = async () => {
      try {
        const response = await axios.get('/api/resume-evaluations'); // Replace with your API endpoint
        setEvaluations(response.data);
      } catch (error) {
        console.error("Error fetching resume evaluations:", error);
      }
    };

    fetchEvaluations();
  }, []);

  return (
    <div className="p-10">
      <h2 className="text-3xl font-bold text-purple-600 text-center mb-8">
        Resume Evaluation
      </h2>
      <ul>
        {evaluations.map(evaluation => (
          <li key={evaluation.id}>{evaluation.name}: {evaluation.result}</li>
        ))}
      </ul>
    </div>
  );
};

export default ResumeEvaluation;

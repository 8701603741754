// src/adminpanel/pages/Chat.jsx
import React from 'react';

const Chat = () => {
  return (
    <div>
      <h2 className="text-3xl font-bold text-purple-600 mb-4">Chat</h2>
      {/* Implement Chat Functionality Here */}
      <p>Welcome to the Chat section. Implement chat features here.</p>
    </div>
  );
};

export default Chat;

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Orders = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('/api/orders'); // Replace with your API endpoint
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  return (
    <div className="p-10">
      <h2 className="text-3xl font-bold text-purple-600 text-center mb-8">
        Orders
      </h2>
      <ul>
        {orders.map(order => (
          <li key={order.id}>Order {order.id}: {order.status}</li>
        ))}
      </ul>
    </div>
  );
};

export default Orders;

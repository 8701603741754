import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const About = () => {
  return (
    <>
    <Navbar/>
    <section className="bg-violet-50 py-8 pt-28">
      {/* Top Section - Main Heading */}
      <div className="container mx-auto text-center mb-12">
        <h1 className="text-4xl font-bold text-indigo-400">
          The Peerless Difference
        </h1>
      </div>

      {/* 1st Section */}
      <section className="flex flex-col md:flex-row items-center justify-between  p-8">
        <div className="md:w-1/2 flex justify-center items-center mb-6 md:mb-0">
          <img
            src="./images/home 1.png"
            alt="Service Illustration"
            className="rounded-lg shadow-lg object-cover"
            style={{ width: "100%", maxWidth: "650px", height: "auto" }}
          />
        </div>
        <div className="md:w-1/2 px-6 md:px-12 text-center md:text-left">
          <h2 className="text-3xl md:text-4xl font-semibold text-indigo-400 mb-6">
            Welcome to Peerless Resume
          </h2>

          <p className="text-base md:text-lg leading-relaxed text-gray-700">
            We offer targeted resume services for all levels and industries. Our
            expert writers prioritize your career goals, ensuring resumes
            reflect your ambitions and are optimized for ATS compatibility.
            Trust Peerless Resume for a personalized solution that enhances your
            job search success and positions you as a top candidate.
          </p>
        </div>
      </section>

      {/* 2nd Section */}
      <section className="flex flex-col md:flex-row items-center justify-between bg-gray-100 p-8">
        <div className="md:w-1/2 px-6 md:px-12 text-center md:text-left">
          <h2 className="text-3xl md:text-4xl font-semibold text-indigo-400 mb-6">
            Who We Are:
          </h2>
          <p className="text-base md:text-lg leading-relaxed text-gray-700">
            Our team of experienced resume writers and career experts brings a
            wealth of knowledge in various industries to deliver tailored
            resumes that align with your career goals. We understand the nuances
            of what employers are looking for and utilize industry best
            practices to create resumes that not only get noticed but also open
            doors.
          </p>
        </div>

        <div className="md:w-1/2 flex justify-center items-center md:mb-0">
          <img
            src="./images/home 2.png"
            alt="Service Illustration"
            className="rounded-lg shadow-lg object-cover"
            style={{ width: "100%", maxWidth: "650px", height: "auto" }}
          />
        </div>
      </section>

      {/* 3rd Section */}
      <section className="flex flex-col md:flex-row items-center justify-between  p-8">
        <div className="md:w-1/2 flex justify-center items-center mb-6 md:mb-0">
          <img
            src="./images/home 1.png"
            alt="Service Illustration"
            className="rounded-lg shadow-lg object-cover"
            style={{ width: "100%", maxWidth: "650px", height: "auto" }}
          />
        </div>
        <div className="md:w-1/2 px-6 md:px-12 text-center md:text-left">
          <h2 className="text-3xl md:text-4xl font-semibold text-indigo-400 mb-6">
            What We Do:
          </h2>

          <p className="text-base md:text-lg leading-relaxed text-gray-700">
            We offer a range of services, including resume writing, cover letter
            creation, LinkedIn profile optimization, and career coaching. Each
            document is meticulously crafted to showcase your strengths and
            achievements, providing you with the tools you need to succeed.
          </p>
        </div>
      </section>
      {/* 4th Section */}
      <section className="flex flex-col md:flex-row items-center justify-between bg-gray-100 p-8">
        <div className="md:w-1/2 px-6 md:px-12 text-center md:text-left">
          <h2 className="text-3xl md:text-4xl font-semibold text-indigo-400 mb-6">
            Why Choose Us:
          </h2>
          <p className="text-base md:text-lg leading-relaxed text-gray-700">
            At Peerless Resume, we pride ourselves on our commitment to
            excellence and client satisfaction. We work closely with you to
            understand your career objectives and ensure your resume reflects
            your true potential. Our goal is to help you achieve your career
            aspirations and stand out in the job market.
          </p>
        </div>

        <div className="md:w-1/2 flex justify-center items-center md:mb-0">
          <img
            src="./images/home 2.png"
            alt="Service Illustration"
            className="rounded-lg shadow-lg object-cover"
            style={{ width: "100%", maxWidth: "650px", height: "auto" }}
          />
        </div>
      </section>

      {/* Dotted Break Line */}
      <hr className=" mx-12 border-dotted border-t-2 border-black my-12" />

      {/* 5th Section */}
      
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="mx-auto text-3xl font-semibold  text-indigo-400 mb-4">
            Get in Touch
          </h2>
          <p className="mx-auto font-semibold text-lg max-w-3xl">
            Ready to elevate your career? <a href="/contact" className="text-blue-600 ">contact us</a> today to learn more about
            our services and how we can assist you in reaching your professional
            goals. We look forward to working with you!
          </p>
        </div>
     
    </section>
    <Footer/>
    </>
  );
};

export default About;





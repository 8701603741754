import React from 'react';


const steps = [
  {
    title: 'Choose a Package',
    icon:'./images/iconoir--select-window.svg',
    description: 'Browse through packages offered by peerless resume service and choose the one that best matches your requirements.',
  },
  {
    title: ' Provide Necessary Information',
    icon:'./images/et--documents.svg',
    description: 'Complete a quick 5-minute questionnaire for your resume writer.',
  },
  {
    title: ' Get Connected to a Writer',
    icon:'./images/material-symbols--communication-rounded.svg',
    description: 'We will connect you with the writer who is perfectly matched to your industry and experience.',
  },
  {
    title: ' Receive Your Documents',
    icon:'./images/fluent-mdl2--document-set.svg',
    description: 'We guarantee timely delivery of your documents, ready for submission. We are here to assist with any revisions.',
  },
];

const HowItWorks = () => {
  return (
    <section className="flex flex-col items-center justify-center bg-gray-100 p-8">
  <h1 className="text-3xl font-semibold mb-16">How IT Works</h1>
  <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
    {steps.map((step, index) => (
      <div key={index} className="p-6">
        <h2 className="text-xl font-semibold mb-2 text-center">{step.title}</h2>
        <img src={step.icon} alt="Step icon" className="mx-auto w-[50px] mb-4" />
        <p className="text-gray-700 text-center">{step.description}</p>
      </div>
    ))}
  </div>
</section>

  );
};

export default HowItWorks;

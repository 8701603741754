import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const Privacy = () => {
  return (
    <>
    <Navbar/>
<div className="flex items-center justify-center h-full bg-violet-100 pt-28">
  <div className="text-center p-8 sm:p-16 md:p-24 lg:p-32 w-full sm:w-[90%] md:w-[75%] lg:w-[65%]">
    <h1 className="text-3xl text-indigo-500 sm:text-4xl md:text-5xl font-bold mb-4">Privacy Policy</h1>
    <p className="text-base sm:text-lg">
      Still have questions? We have answers for your non-legal inquiries on our{" "}
      <a href="/faqs" className="text-blue-600 ">FAQs page</a>. Or, learn more{" "}
      <a href="/about" className="text-blue-600 "> about us</a>.
    </p>
  </div>
</div>
    <div className="bg-gray-100 p-8">
      <div className="w-[90%] mx-auto bg-gray-50 p-8 shadow-lg rounded-lg">
        <h1 className="text-3xl text-indigo-400 font-bold mb-6">Peerless Resume Privacy Policy</h1>
        <p className="text-sm mb-2">Last Updated: June 02, 2024</p>
        <p className="text-lg mb-4">
          Welcome to Peerless Resume's Privacy Policy. This document outlines how Peerless Resume
          ("we," "us," or "our") collects, uses, discloses, and protects your personal information
          when you use our website, products, and services ("Services"). By accessing or using our
          Services, you agree to the terms outlined in this Privacy Policy.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
        <p className="mb-4">
          At Peerless Resume, we are committed to protecting your privacy and safeguarding your
          personal information. This Privacy Policy aims to inform you about the data we collect,
          how we use it, and your rights regarding your personal information.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
        <ul className="list-disc list-inside mb-4">
          <li>Personal Information: Information that can be used to identify you, such as your name, contact information, and payment details.</li>
          <li>Resume Information: Information provided by you for the purpose of creating or updating your resume, including employment history, educational background, skills, and qualifications.</li>
          <li>Usage Information: Information about how you interact with our website and Services, such as your IP address, browser type, and device identifiers.</li>
          <li>Cookies and Similar Technologies: We use cookies and similar technologies to enhance your experience, analyze usage patterns, and personalize content.</li>
        </ul>
        <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
        <ul className="list-disc list-inside mb-4">
          <li>Providing and improving our Services to you.</li>
          <li>Customizing your resume and job application materials based on your preferences and requirements.</li>
          <li>Communicating with you about your account, transactions, and updates to our Services.</li>
          <li>Analyzing usage trends and improving our website and Services to better serve our users.</li>
          <li>Marketing and promotional purposes, with your consent where required by law.</li>
          <li>We refrain from selling clients’ personal infomration in exchange for monetary compensation.</li>
        </ul>
        <h2 className="text-2xl font-semibold mb-4">Data Sharing and Disclosure</h2>
        <p className="mb-4">
          We may share your personal information with third parties under certain circumstances, including:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Service Providers: We may share your information with third-party service providers who assist us in providing our Services, such as payment processors, cloud storage providers, and marketing partners.</li>
          <li>Legal Compliance: We may disclose your information to comply with legal obligations, enforce our policies, or protect the rights, property, or safety of Peerless Resume, our users, or others.</li>
          <li>Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred to a third party as part of the transaction.</li>
        </ul>
        <h2 className="text-2xl font-semibold mb-4">Data Retention</h2>
        <p className="mb-4">
          We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Data Security</h2>
        <p className="mb-4">
          We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Your Rights and Choices</h2>
        <ul className="list-disc list-inside mb-4">
          <li>Access, update, or delete your information by logging into your account or contacting us directly.</li>
          <li>Object to or restrict the processing of your information in certain circumstances.</li>
          <li>Opt-out of receiving marketing communications by following the instructions provided in the communication or contacting us.</li>
        </ul>
        <h2 className="text-2xl font-semibold mb-4">International Data Transfers</h2>
        <p className="mb-4">
          Your information may be transferred to and processed in countries other than your own, where data protection laws may be different. By using our Services, you consent to the transfer of your information to countries outside of your country of residence.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Children's Privacy</h2>
        <p className="mb-4">
          Our Services are not directed to children under the age of 18, and we do not knowingly collect personal information from children. If you believe that we have inadvertently collected information from a child, please contact us immediately.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Changes to this Privacy Policy</h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Policy on our website or through other means.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-4">
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at [contact@peerlessresume.com].
        </p>
        <h2 className="text-2xl font-semibold mb-4">Conclusion</h2>
        <p className="mb-4">
          Thank you for taking the time to read our Privacy Policy. We are committed to protecting your privacy and ensuring the security of your personal information. By using our Services, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Information Collected Automatically</h2>
        <p className="mb-4">
          In addition to the information you provide directly, we also collect certain information automatically when you use our Services. This may include:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Log Information: We collect log data when you access our website or use our Services, including your IP address, browser type, operating system, and device information.</li>
          <li>Usage Data: We collect information about your interactions with our Services, such as the pages you visit, the features you use, and the actions you take.</li>
          <li>Cookies and Similar Technologies: We use cookies, web beacons, and other tracking technologies to collect information about your browsing behavior, preferences, and session data. These technologies help us improve our Services, personalize your experience, and analyze trends.</li>
        </ul>
        <h2 className="text-2xl font-semibold mb-4">Cookie Policy</h2>
        <p className="mb-4">
          Our Cookie Policy provides detailed information about how we use cookies and similar technologies, including the types of cookies we use, how we use them, and your choices regarding cookie settings. You can find our Cookie Policy [here](link to Cookie Policy).
        </p>
        <h2 className="text-2xl font-semibold mb-4">Third-Party Links and Services</h2>
        <p className="mb-4">
          Our Services may contain links to third-party websites, services, and content that are not owned or controlled by Peerless Resume. We are not responsible for the privacy practices or content of these third parties. We encourage you to review the privacy policies of these third parties before interacting with their websites or services.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Data Subject Requests</h2>
        <p className="mb-4">
          As a data subject, you have certain rights regarding your personal information under applicable data protection laws. These rights may include the right to access, update, or delete your information, as well as the right to object to or restrict the processing of your information. To exercise your rights or make a data subject request, please contact us using the information provided in the "Contact Us" section of this Privacy Policy.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Data Protection Officer</h2>
        <p className="mb-4">
          Peerless Resume has a Data Protection Officer (DPO) to oversee our data protection practices and ensure compliance with applicable privacy laws and regulations. If you have any questions or concerns about our data practices or this Privacy Policy, you can contact our DPO at [dpo@peerlessresume.com].
        </p>
        <h2 className="text-2xl font-semibold mb-4">Data Breach Notification</h2>
        <p className="mb-4">
          In the event of a data breach involving your personal information, we will notify you and any relevant regulatory authorities as required by law. Our notification will include details about the breach, the types of information affected, and the steps we are taking to mitigate the impact of the breach.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Regulatory Compliance</h2>
        <p className="mb-4">
          We are committed to complying with applicable privacy laws and regulations, including the General Data Protection Regulation (GDPR) in the European Union and the California Consumer Privacy Act (CCPA) in the United States. If you are a resident of the European Economic Area (EEA) or California, you may have additional rights under these laws, which are outlined in this Privacy Policy.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Governing Law</h2>
        <p className="mb-4">
          This Privacy Policy and any disputes arising from or relating to it are governed by the laws of [Jurisdiction], without regard to its conflict of law principles. Any legal action or proceeding arising from or relating to this Privacy Policy shall be brought exclusively in the courts of [Jurisdiction].
        </p>
        <h2 className="text-2xl font-semibold mb-4">Entire Agreement</h2>
        <p className="mb-4">
          This Privacy Policy constitutes the entire agreement between you and Peerless Resume regarding the collection, use, and disclosure of your personal information. Any prior agreements, representations, or understandings regarding the same are superseded by this Privacy Policy.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Waiver and Severability</h2>
        <p className="mb-4">
          The failure of Peerless Resume to enforce any provision of this Privacy Policy shall not be deemed a waiver of such provision. If any provision of this Privacy Policy is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Updates to this Privacy Policy</h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Policy on our website or through other means.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-4">
          If you have any questions, concerns, or complaints about this Privacy Policy or our data practices, please contact us at [contact@peerlessresume.com].
        </p>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Privacy;
